@import "../../variables.scss";

.meta-data-container {
  padding: 0.5rem;
  padding-right: 0;
  padding-top: 0;
  width: 300px;
}

.meta-data {
  display: "flow";
  background-color: $panel-contents;
  padding: 1rem;
  font-size: 0.9rem;
}

.meta-data-item {
  display: inline-block !important;
  margin-right: 1rem;
}
