@import "../../../variables.scss";

.collapsible {
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  float: left;
  margin-top: 0.5rem;  
  padding: 0.5rem;
  background-color: white;
  min-height: 2rem;
  display: flex;  
}

.track-active {
  background-color: $orange;
  color: white;
}

.collapsible-content {
  //padding: 0 18px;  
  display: none;
  overflow: hidden;  
  width: 100%;  
}

.collapsible-icon {
  float: right;  
  padding-right: 0.7rem;
  position: relative;  
  top: 3px;
}

.reset-icon {
  margin-right: 0.2rem;
  position: relative;  
  top: -1px;
}