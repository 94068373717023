@import '../../variables';

.borehole-list-header {
    display: flex;
}

.view-icon {
    width: 36px;    
    width: 36px;    
    cursor: pointer;
    padding: 6px;
}

.view-icon-background {
    background-color: #f6f6f6;    
    border-radius: 3px;
}

.data-viewer-columns {
    width: 9rem;
    max-width: 9rem;
    img {
        min-width: 16px;
        width: 16px;
        height: 16px;
    }

    .column {
        min-width: 16px;
    }
}

.data-viewer-column {    
    color:#363636;    
}

.geotek-table {
    position: relative;
    
    th {
        background-color: #f6f6f6;
        font-weight: normal;
        border: none !important;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        position: sticky;
        top: 0; 
    }

    th:not(:last-child) {
        cursor: pointer;
    }

    th:not(:first-child):before {
        content: "";
        text-align: right;
        border-right: solid 1.5px #cbcbcb;
        position: relative;
        right: 5px;         
    }

    tr:hover {
        background-color: #f1f4f7;
      }

    td {
        border: none !important;        
        padding-top: 1rem !important;        
    }

    .borehole-list-header {
        display: inline-flex;
        width: 100%;
    }

    .sort-icon {
        width: 1.5rem;      
        margin-left: auto;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .sort-icon-holder {
        width: 1.5rem;
        height: 1.5rem;
        background-color: #363636;
    }

    .project-edit {
        i {
            text-align: right;            
            position: relative;
            float: right;
            right: 5px;      
            visibility: hidden;
        }
        &:hover i {
            visibility: visible;
          }
        // &:hover::after {
        //     content: "P";
        //     background-image: '/images/icons/Sorting Arrow Asc.svg';            
        //     text-align: right;            
        //     position: relative;
        //     float: right;
        //     right: 5px;         
        // }
    }

    .list-icon {
        position: relative;
        top: -2px;
        width: 16px;
        fill: rgb(54,54,54);

        &:hover {
            fill: $orange;
        }
    }    
}