.print-dpeth-input {
  width: 10ch !important;
}

.print-depth {
  margin-right: 0.2rem !important;
}

.delete-data-for-depth-range {
  height: 20px;
  float: right;
}
