@import "../../variables.scss";

.wrapperItem {
  padding: 16px;
  box-shadow: 0px 1px 2px lightgrey;
  background-color: $card-background-color;
}

.leftSide {
}

.leftSide > label {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.percentage {
  margin-left: 12px;
}

.upload-progress {
  width: 100%;
}
