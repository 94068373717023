.minor-tick {
  line {
    stroke: #999999;
    stroke-width: 1px;
    stroke-dasharray: 4, 3;
  }
}

.depth-track-container {
  display: flex;
  align-items: flex-start;
  position: absolute;
}

.depth-track {
  left: 0px;
  z-index: 98;
}
