$card-background-color: #f6f6f6;

$blue: rgba(0, 54, 113, 1);
$light-blue: #395471;

$navbar-background-color: $blue;
$navbar-item-color: #9d9d9d;
$navbar-item-hover-color: #ffffff;
$navbar-item-hover-background-color: $blue;

$orange: #e48f00;

$primary: $orange;
$scondary: $blue;

$success: #20c19e;

$control-border: $blue;

$light: $orange;

$panel-background: #f6f6f6;
$panel-contents: white;

$tabs-link-padding: 0.5em 0.7em;
