@import "../../variables.scss";

.meta-data {
  display: "flow";
}

.meta-data-item {
  display: inline-block !important;
  margin-right: 1rem;
}

.white-label {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.white-icon {
  color: white;
  position: relative;
  top: 15px;
}

.no-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.highlighted-track {
  background-color: $orange !important;
  color: white;
}

.image-track-container {
  display: flex;
  align-items: flex-start;
  background-color: black;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.adjustments-panel {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  background-color: $panel-background;
  min-height: 30rem;
}

.right-side-panel {
  position: fixed;
  //height: 100%;
  z-index: 1;
  top: 56px;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 4px;
  background-color: $panel-background;
  min-height: 30rem;
}

.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  background-color: $panel-background;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

.sidebar-main {
  transition: margin 0.5s;
}

.button {
  &.highlighted {
    //border: 2px solid $blue !important;
    background-color: $orange !important;
  }
}

.level-scroll {
  margin-right: 0 !important;
}

.borehole-name-container {    
  justify-content: center;  
  margin-left: 20px;
  margin-right: 40px;
  display: flex;
  flex-wrap: wrap;  
  flex-grow: 2;
  flex-shrink: 1;
  //margin-right: 300px;
}

.borehole-name {
  margin-top: 0.5rem;
}

.image-viewer-header {
  //width: 100%;
  display: flex;
  flex-wrap: wrap;  
  justify-content: center;  
}

.mouse-wheel-control {
  padding-top: 0.5rem;
  margin-left: 1rem;
  display: flex;
  flex-wrap: wrap;  
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 200px;
  //align-items: center;
}

.mouse-wheel-control-text {
  display: flex;
  align-items: top;
  margin-right: 0.6rem;
}

.mouse-wheel-control-control {  
  display: flex;
  align-items: top;  
}

.mouse-wheel-control-switch {    
    margin-left: 4px;
    position: relative;
    bottom: 7px;
}

.fullpage {
  height: 100% !important;
}