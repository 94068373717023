.image-tooltip {
  text-decoration: none;
}

.image-tooltip span {
  display: none;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: black;
  background: white;
}

.image-tooltip span img {
  float: left;
}

.image-tooltip:hover span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: auto;
  min-width: 200px;
  max-width: 200px;
  min-height: 128px;
  border: 1px solid black;
  margin-top: 12px;
  margin-left: 32px;
  overflow: hidden;
  padding: 8px;
}

.image-legend {
  //max-width: 150px;
  max-height: 45px;
  min-height: 45px;
}
