@import "../variables";

.small-loader-wrapper {
  position: relative;
  z-index: -1;
  transition: opacity 0.5s;
  border-radius: 6px;
  opacity: 0;

  .small-loader {
    border: 4px solid $orange;
    border-radius: 50%;
    border-top: 4px solid $blue;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &.is-active {
    opacity: 1;
    z-index: 1;
  }
}
