.text-track-teaxarea {
    max-width: 10rem;
    min-width: 10rem;    
    max-height: 10rem;
    padding-top: 0.4rem !important;
    padding-bottom: 0.2rem !important;
    &.textarea {
        min-height: 40px !important;
    }    
}

.text-edit-table {
    td {
        padding: 0.1rem !important;
        padding-top: 0.4rem !important;
        padding-bottom: 0.4rem !important;
    }
}

.text-track-tooltip {
    background-color: #f9f9f7;
    position: absolute;
    z-index: 10;
    opacity: 0;
    font-size: 0.8rem;  
    border-radius: 3px;
    padding: 3px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: left;
    min-width: 10em;
    max-width: 40rem;
    color: black;
    word-wrap: break-word;
}

.text-track-description {
    word-wrap: break-word;   
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    //-webkit-line-clamp: 4;    
    margin: 3px;

    // visibility: visible;
    // margin: 3px;
    // word-wrap: break-word;    
    // text-overflow: hidden; 
    // overflow: hidden;            
    // -webkit-box-orient: vertical;    
    // display: -webkit-box;
    // text-overflow: ellipsis;
}