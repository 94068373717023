@import '../../../variables.scss';

.job-progress-column {
    max-width: 20rem;
}

.hub-state {
    width: 1rem;
    height: 1rem;    
    border-radius: 100%;
    display: inline-block;
    margin-left: 0.3rem;
    position: relative;
    top: 2px;
}