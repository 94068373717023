@import '../../../variables.scss';

.lookup-color {
    display: inline-block;
    width: 20px;
    height: 20px;   
    position: relative; 
    top: 3px;
    border: 1px solid black;
}

.lookup-color-inline-edit {
    display: inline-block;
    width: 20px;
    height: 20px;   
    position: relative; 
    top: 3px;
    border: 1px solid black;
    cursor: pointer;
}

.lookup-color-edit {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid black;
    cursor: pointer;
    margin-left: 1rem;
    position: relative;
    top: 8px;
}

.lookup-color-select {
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
}

.color-code {
    cursor: pointer;
}