// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");

@import "variables";

// Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/initial-variables.sass";
$navbar-breakpoint: $tablet;
@import "../node_modules/bulma/bulma.sass";

@import "../node_modules/@creativebulma/bulma-badge/src/sass//index.sass";
// @import "../node_modules/bulma/sass/utilities/_all.sass";
// @import "../node_modules/bulma/sass/base/_all.sass";
// @import "../node_modules/bulma/sass/elements/button.sass";
// @import "../node_modules/bulma/sass/elements/container.sass";
// @import "../node_modules/bulma/sass/elements/form.sass";
// @import "../node_modules/bulma/sass/elements/title.sass";
// @import "../node_modules/bulma/sass/elements/tag.sass";
// @import "../node_modules/bulma/sass/elements/progress.sass";
// @import "../node_modules/bulma/sass/elements/table.sass";
// @import "../node_modules/bulma/sass/components/card.sass";
// @import "../node_modules/bulma/sass/elements/notification.sass";
// @import "../node_modules/bulma/sass/elements/content.sass";
// @import "../node_modules/bulma/sass/elements/box.sass";
// @import "../node_modules/bulma/sass/elements/other.sass";
// @import "../node_modules/bulma/sass/components/navbar.sass";
// @import "../node_modules/bulma/sass/components/menu.sass";
// @import "../node_modules/bulma/sass/components/tabs.sass";
// @import "../node_modules/bulma/sass/components/modal.sass";
// @import "../node_modules/bulma/sass/layout/hero.sass";
// @import "../node_modules/bulma/sass/layout/section.sass";
// @import "../node_modules/bulma/sass/components/level.sass";
// @import "../node_modules/bulma/sass/grid/columns.sass";
// @import "../node_modules/bulma/sass/form/_all.sass";
// @import "../node_modules/bulma/sass/utilities/derived-variables.sass";
@import "~bulma-switch";
@import "switch-overrides";
@import "overrides";

$sizes: (0, 1, 2, 3, 4, 5, 6, 7, 8);
$positions: ("top", "left", "bottom", "right");

@each $size in $sizes {
  @each $position in $positions {
    .m#{str-slice($position, 0, 1)}-#{$size} {
      margin-#{$position}: $size + rem;
    }

    .m#{str-slice($position, 0, 1)}-#{$size}5 {
      margin-#{$position}: ($size + 0.5) + rem;
    }

    .p#{str-slice($position, 0, 1)}-#{$size} {
      padding-#{$position}: $size + rem;
    }

    .p#{str-slice($position, 0, 1)}-#{$size}5 {
      padding-#{$position}: ($size + 0.5) + rem;
    }
  }
}
