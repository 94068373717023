@import '../../variables.scss';

.admin-menu-container {
    display: flex;
    height: 100%;
    .section {
        flex-grow: 1;
    }
}

.admin-menu {
    position: fixed;
    height: 100%;
    overflow: auto;
    height: 100pv;
    min-width: 250px;
    background-color: $panel-background;
    color: rgb(74, 74, 74);
    padding: 2rem;
    padding-right: 0;
    position: sticky;
    top: 56px;
    //min-height: 100rem;

    .settings-heading {
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    .settings-sub-heading {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;        
    }

    a {
        color: rgb(74, 74, 74);
        padding: 0.2rem;
        display: block;
        &.active {
            background-color: white;
            font-weight: 600;
        }
    }
}