.lithology-edit-header {
    position: sticky;
    top: -4px;
    z-index: 5;
    background-color: white;    
}

.lithology-select-box {
    width: 13px;
    height: 13px;
    display: inline-block;
    border: 1px solid black;
    margin-right: 0.3rem;
    position: relative;
    top: 1px
}