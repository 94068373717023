@import "variables";

.switch[type="checkbox"].is-outlined + label::before,
.switch[type="checkbox"].is-outlined + label:before,
.switch[type="checkbox"].is-outlined:checked + label::before,
.switch[type="checkbox"].is-outlined:checked + label:before {
  border-color: $blue !important;
}

.switch[type="checkbox"].is-outlined + label::after,
.switch[type="checkbox"].is-outlined + label:after,
.switch[type="checkbox"].is-outlined:checked + label::after,
.switch[type="checkbox"].is-outlined:checked + label:after {
  background-color: $blue !important;
}
