@import "../../variables";

.rc-slider-mark {
  padding-top: 7px;
}
.rc-slider-step {
  span {
    background-color:  $blue;
    border-color:  $blue;
  }
}
.rc-slider-handle {
  background-color: $orange;
  border-color: $orange;
  cursor: pointer;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: 0 0 0 2px $orange;
}

.rc-slider-tooltip {
    z-index: 1;
}

.rc-slider-tooltip-inner {
  background-color: $orange;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: none !important;
}

.rc-slider-tooltip-arrow {
  border-top-color: $orange !important;
}

.slider-value {
  flex-grow: 0 !important;  
  justify-content: left !important;  
  max-width: 3rem;
}

.rc-slider-disabled {
  background-color: transparent !important;
}