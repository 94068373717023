@import '../../../variables.scss';

.editable-row {
     .edit-icons {        
        text-align: right;
        display: flex;
        flex-grow: 1;
        justify-items: right;
        align-items: flex-end;
        float: right;
        visibility: hidden;
        cursor: pointer;

        :first-child {
            margin-right: 3px;
        }
     }

     .input {
        margin-left: -10px;
     }

     &:hover {
        .edit-icons {
            visibility: visible;
        }
     }

     .row-buttons {  
        text-align: right;      
        display: flex;
        justify-items: right;
        align-items: flex-end;
        float: right;
     }
}

