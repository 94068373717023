.list-roles-viewer-columns {
    width: 10rem;
    max-width: 10rem;   

    .column {
        min-width: 16px;
    }
}

.list-roles-viewer-column {    
    color:#363636;    
}
