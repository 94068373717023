@import "../variables";

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  opacity: 0;

  .loader {
    border: 10px solid $orange;
    border-radius: 50%;
    border-top: 10px solid $blue;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &.is-active {
    opacity: 1;
    z-index: 1;
  }
}
