@import "../index.scss";

.progress-percent {
  max-width: 3rem;
  font-size: 0.8rem;
}

.progress-message {
  font-size: 0.8rem;
}

.progress-level {
  margin-bottom: 0.1rem !important;
}

.progress-level-item {
  margin-bottom: 0.1rem !important;
}

.is-blue {
  background-color: $blue;
}

