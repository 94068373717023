@import "../../../variables.scss";

.track-order {
  min-height: 2rem;
  padding: 0.5rem;
  background-color: white;
  margin-top: 0.5rem;
  display: flex;
}

.track-order-label {
  flex: 1 1 80%;
  padding-left: 0.5rem;
  align-items: center;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.track-order-icon {
  flex: none;
  align-self: center;
  align-self: center;
  align-items: center;
}

.group-header {
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;  
  margin-top: 0.5rem;  
  padding: 0.5rem;
  background-color: white;
  min-height: 2rem;
 display: flex; 
}