@import "../../../variables.scss";

.group-track-header {
    
}

.group-track-active {
    background-color: $blue !important;
    color: white;
}

.group-track-curves {
    margin-left: 1.5rem;
    margin-top: 0.5rem;
}

.group-track-curve {
    margin-bottom: 1rem;
}

.group-track-delete-checkbox {
    position: relative;
    top: -5px;
    margin-right: 0.5rem;
}