.image-file-name-filter {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    .image-file-name-filter-text {
        margin-bottom: 0 !important;
    }      
}

.image-file-name-filter > * {
    margin-right: 0.5rem;
}
