.image-depth-track {
  background-color: black;
}

.svg-imagedepthtrack {
  background-color: black;
  color: white;
}

.image-minor-tick {
  opacity: 0;
}
