.track-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-size: 0.8rem !important;
}

.track-label-container {
  cursor: pointer;
  padding: 0;
  vertical-align: "middle";
  margin: 0 !important;
}

.track-header-columns {
  margin: 0 !important;
  margin-top: 3px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.track-header-min-value {
  padding: 0 !important;
}

.track-header-max-value {
  padding: 0 !important;
}

.track-header {
  text-overflow: ellipsis;
  min-height: 54px;
  line-height: 1rem;
}

.track-header-label {
  vertical-align: super;
  height: 21px;
}

.track-header-units {
  font-size: 0.8rem;
  flex-shrink: 1 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-overflow: ellipsis;
}

.track-header-padding {
  height: 78px;
}

.track-header-line {
  margin-top: 3px;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 10px;
}

.track-header-icon {
  font-size: 1rem;
  top: 4px !important;
  //height: 5px;
  //max-height: 1rem;
}