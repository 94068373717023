@import "../../variables";

.image-drop-area {
  border: $control-border 1px dashed;
  border-radius: 5px;
  color: $control-border;
  text-align: center;
  max-width: 40rem;
  &.active {
    border-color: $orange;
  }
}

.image-drop-area-inner {
  padding: 2rem;
}

.image-upload-area {
  width: 100%;
}
