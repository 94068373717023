.log-track-container {
  display: flex;
  align-items: flex-start;
  background-color: white;
}

.button-icon {
  padding: 0.5rem !important;
  width: 40px;
}

.tab-icon {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  fill: black;
}

.white-icon {
  fill: white;
}

.log-viewer-title {
  margin-left: 2rem;
  margin-bottom: 0 !important;
}
