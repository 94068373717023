@import "../../variables.scss";

.image-filter-container {
  padding: 1rem;
  width: 300px;
}

.image-filter-close-button {
  position: relative;
  left: -20px;
}

.image-filter {
  font-size: 0.9rem;

  .slider-column {
    max-width: 5rem;
    vertical-align: middle !important;
  }

  .slider {
    width: 80px;
  }

  td {
    padding: 0.2rem !important;
    border: none !important;
    vertical-align: middle !important;
  }
}

.image-filter-select {
  padding: 0.5rem;
  width: 100%;
}
