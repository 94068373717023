@import "../index.scss";

.user-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  background-color: $orange;
  color: white;
  font-size: 1.2rem;
}

.user-details-container {
  display: flex;
}

.user-details-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  background-color: $orange;
  color: white;
  font-size: 1rem;
  margin-right: 0.5rem;
}

.navbar-item {
  &.user-details {
    //    color: $grey-darkest !important;
    display: flex;

    .user-details-email-address {
      display: block;
      color: black !important;
      font-size: 0.8rem;
    }
  }

  &.active {
    color: white;
  }
  
  button {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
}

.navbar-dropdown {
  right: 0px;
  left: auto;

  // &.is-boxed::before {
  //   content: "";
  //   height: 0;
  //   width: 0;
  //   border-left: 6px solid transparent;
  //   border-right: 6px solid transparent;
  //   border-bottom: 6px solid #fff;
  //   position: absolute;
  //   top: -6px;
  //   right: 26px;
  //   margin: 0 0 0 -3px;
  //   z-index: 100;
  // }
}

.user-details-name {
  color: $grey-darker;
}

.navbar-divider {
  margin-left: 1em;
  margin-right: 1em;
  padding-top: 0em;
  margin-top: 0em;
}

.navbar-dropdown a.navbar-item {
  color: $grey-darker;
}

.navbar-dropdown a.navbar-item {
  padding-right: 1rem;
  color: black;

  &:hover {
    color: $orange !important;
  }
}

.navbar-borehole-name {
  color: white !important;
  font-size: 1.2rem;
}

.atlas-title {
  font-size: 1.2rem;
  padding-top: 0;
  padding-bottom: 0;
  color: white;;
}

.client-name {
  //font-weight: bold;  
  padding-top: 0;
  padding-bottom: 0;
}

.menu-icon {
  fill: #9d9d9d;
  width: 16px;
  
  &:hover {
    fill: white;
  }
}

.navbar-item {
  &.active {    
    svg {
      fill: white;
    }
  }
}

.navbar-burger {
  &:hover {
    color: white;  
    background-color: transparent;
  }
}