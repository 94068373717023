.upload-table {
  max-width: 60rem;

  .type-column {
    max-width: 7rem;
    min-width: 7rem;
  }

  .progress-column {
    max-width: 15rem;
    min-width: 10rem;
  }
}

.scale-image-progress {
  max-width: 40rem;
}

.depth-number-field {
  max-width: 7rem;
}

.template-column {
  padding-bottom: 0;
  margin-bottom: 0 !important;
}

.react-checkbox-tree {
  ol {
    margin-left: 0 !important;
    margin-top: 0 !important;
    list-style-type: none;
  }
  li {
    list-style-type: none;
  }
}

.delete-file-icon {
  min-width: 20px;  
}

.warning-icon {
  position: relative;
  top: 3px;
}