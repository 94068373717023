@import "../../variables.scss";

.popout-panel-heading {
  background-color: $light-blue;
  color: white;
  font-size: 1.5rem;
  padding: 0.5rem;
}

.left-side-panel {
  position: fixed;
  //height: 100%;
  z-index: 1;
  top: 56px;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 4px;
  background-color: $panel-background;
  min-height: 30rem;
}

.save-button {
  padding-left: 0.55rem !important;
  padding-right: 0.55rem !important;
}


.sticky-buttons {
  background-color: white;
  position: sticky;
  bottom: 0;
  padding-top: 0.5rem;
}