@import "../../variables.scss";

.wrapper {
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  width: 400px;
  overflow: scroll;
  max-height: 400px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  h4 {
    background-color: $blue;
    color: white !important;
    margin: 0;
    padding: 20px;
  }
}

.closeButton {
  position: absolute;
  top: 18px;
  right: 12px;
  background: transparent;
  border: unset;
  font-size: 18px;
  cursor: pointer;
}

.closeButton:hover {
  opacity: 0.5;
}
