.resource-list {
    display: flex;
    flex-wrap: wrap;
}

.resource-permission {
    margin-right: 1rem;    
    margin-bottom: 1rem;
    width: 200px;

    input[type=checkbox] {
        margin-right: 0.5rem;
    }
}

.resource-permission-header {
    font-weight: 600;
    font-size: 1.1rem;
}

.select-project-permissions {
    display: flex;

    .button {
        margin-left: 1rem;
    }
}

.role-name {
    max-width: 20rem;
}

.permissions-table {
    width: auto !important;

    tbody {
        tr:hover {
            background-color: #f1f4f7;
        }
    }

    .project-column {
        min-width: 20rem;
    }

    .allow-deny-column {
        width: 5rem;
        font-weight: normal;
    }
}