img.borehole {
  // max-height: 2000px;
  // max-height: 650px;
  // min-height: 650px;
  display: block;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  border: black 1px solid;
  /* IE, only works on <img> tags */
  -ms-interpolation-mode: nearest-neighbor;
  /* Firefox */
  image-rendering: crisp-edges;
  /* Chromium + Safari */
  image-rendering: pixelated;
}

.image-column {
  font-size: 0;
  flex-grow: 0 !important;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 !important;
  //overflow: auto;
  //width: 100%;
  //height: 100%;
}

.flex-item {
  flex: 0 0 auto;
  padding: 0 !important;
}

.track-container {
  display: flex;
  align-items: flex-start;
  position: absolute;
}

.borehole-header-container {
  min-width: 300px;
  position: relative;  
}

.borehole-header {
  position: absolute;
  margin-top: -10px;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.group-outline {
  border: 1px solid #AAAAAA;
  
  margin-right: 5px;
}