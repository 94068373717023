.curve-attributes-tabs {
  margin-bottom: 0 !important;
}

.curve-attributes-box {
  padding: 10px;
  padding-top: 1rem;
  border-radius: 0 0 5px 5px;
  border: 1px solid #dbdbdb;
  border-top: none;
}

.small-icon {
  position: relative;
  font-size: 1rem;
  top: -2px;
  left: 5px;
}

.delete-icon {
  cursor: pointer;
  float: right;
  position: relative;
  top: -4px;
}
