.depth-filter-value {
    font-size: 0.8rem;

    .depth-filter-value-right {
        float: right;
    }
}

.depth-filter {
    padding-left: 1rem;
    padding-right: 1rem;
}