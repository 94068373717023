@import "../../variables.scss";

.google-map {
  width: 100%;
  height: 80vh;
}

.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

.map-container {
  display: flex;
}

.map-borehole-list {
  min-width: 200px;
  height: 100%;

  .item {
    padding: 0.5rem;
    cursor: pointer;

    &.selected {
      background-color: $light-blue;
      color: white;
    }
  }
}
