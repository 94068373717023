.list-users-viewer-columns {
    width: 15rem;
    max-width: 15rem;   

    .column {
        min-width: 16px;
    }
}

.list-users-viewer-column {    
    color:#363636;    
}

.grey-email-address {
    color: #bebebe;
}

.login-audit-date-selector {
    max-width: 21rem;
}

.login-browser {
    max-width: 20rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}