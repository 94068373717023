// Overrides
@import "./variables.scss";

html {
  height: 100vh;  
  overflow-x: hidden;  
}

body {
  font-family: Roboto, "Telefon", "Segoe UI", "Montserrat", sans-serif;  
}

.card {
  width: 300px;
  margin: 0.5rem;
}

.section {
  padding-top: 2rem;
  padding-bottom: 0;
}

.material-icons {
  &.rotated {
    transform: rotate(90deg);
  }
}

.badge-button {
  position: relative;
}

.icon-inline {
  position: relative;
  top: 5px;
}

.tabs {
  ul {
    margin-left: 0;
    margin-top: 0;
  }
}

.ReactModal__Overlay {
  z-index: 101;
}

.react-confirm-alert-overlay {
  z-index: 102 !important;
}

.react-confirm-alert-button-group {
  button:first-child {
    background-color: $danger;
  }

  button:last-child {
    background-color: $orange;
  }
}

.select__menu {
  z-index: 999;
  //position: fixed !important;
}

.button {
  box-shadow: none !important;

  &.is-tab {
    background-color: $light-blue;
    border-color: transparent;
    color: white;
    width: 40px;
  }
}