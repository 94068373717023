.focus-text {
  font-size: 0.8rem;
}

.tooltip {
  position: absolute;
  z-index: 10;
  opacity: 0;
  font-size: 0.8rem;
  background-color: #00000066;
  border-radius: 3px;
  padding: 3px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
  min-width: 10em;
  color: white;
}

.tooltip-box {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}

.header-color-box {
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.color-picker {
  width: 1.5rem;
  height: 1.5rem;  
  cursor: pointer;
  border: 1px black solid;
}

.color-picker-centered {
  margin-left: auto;
  margin-right: auto;
}

.overlay {
  opacity: 0;
}

.svg-chartdepthtrack {
  background-color: white;
}

.select__input {
  color: transparent;
  caret-color: transparent;
  &:focus {
    outline: none !important;
  }
}

.button-column {
  padding: 12px !important;
  padding-top: 0px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;

  &:first-child {
    padding-left: 0px !important;
  }

  &:last-child {
    padding-right: 0px !important;
  }
}

.track-buttons {
  margin-top: 0px !important;
  max-width: 166px;
}

.track-buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 4px;
}

.track {
  padding: 1px;
}

.selected-track-outline {
  border: 1px solid black;
  padding: 0px;
}

.react-resizable {
  overflow: hidden !important;
}

.react-resizable-handle-e {
  cursor: col-resize;
  transform: none;
  -webkit-transform: none;
  top: 0;
}

.react-resizable-handle {
  background-image: none;
  height: 100%;
}

.outer-grid-lines line {
  stroke: #363636;
  stroke-width: 1px;
}

.vertical-grid-lines line {
  stroke: #dcdcdc;
  stroke-width: 0.5px;
}

.horizontal-grid-lines line {
  stroke: #808080;
  stroke-width: 0.5px;
}

.edge-tick {
  opacity: 0 !important;
}

.grid-minor-tick {
  line {
    stroke: #dcdcdc;
    stroke-width: 0.5px;
  }
}
