@import "../../variables.scss";

.track-type-list {
  overflow-x: none;
}

.add-track-panel-contents {
  padding: 0.5rem;
  padding-right: 1.5rem;
  background-color: $panel-contents;
  font-size: 0.9rem;  
}

.panel-contents {
  //padding: 0.5rem;
  //background-color: $panel-contents;
  font-size: 0.9rem;
}

.panel-section {
  padding: 1rem;
  padding-right: 1.5rem;
  padding-top: 8px;
  padding-bottom: 1rem;
  //margin-bottom: 1rem;
  background-color: $panel-contents;
}

.add-track-panel {
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  background-color: $panel-background;
}

.right-side-menu-panel {
  width: 50px;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  background-color: $panel-background;
  padding-left: 5px;
}

.left-side-menu-panel {
  width: 50px;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  background-color: $panel-background;
  padding-left: 5px;
}

.badge {
  border-radius: 10px !important;
  box-shadow: 0 0 0 1.5px white !important;
  height: 10px !important;
  min-width: 10px !important;
  right: 2px !important;
  top: 2px !important;
  background-color: #d1230c !important;
}

.right-panel-container {
  padding: 0.5rem;
  padding-left: 0;
  padding-top: 0;
  width: 427px;
}

.left-panel-container {
  padding: 0.5rem;
  padding-right: 0;
  padding-top: 0;
  width: 300px;
}

.panel-tab {
  margin-bottom: 0 !important;
}

.adjustment-tab-tab {
  background-color: white;
  padding: 1rem;
}
